
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    trainingDocuments: [] as Array<{ title: string; slug: string; }>,
  }),
  created() {
    this.loadTrainingDocuments();
  },
  methods: {
    async loadTrainingDocuments() {
      this.status = 'loading';

      const responseData = await this.api({ url: 'business-portal/training_documents/' });

      if (responseData.status === 200) {
        this.trainingDocuments = responseData.body;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
  },
});
