
import { defineComponent } from 'vue';
import PageNotFoundContent from '@/components/PageNotFoundContent.vue';

export default defineComponent({
  components: {
    PageNotFoundContent,
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    setTimeout(() => {
      this.loadTrainingDocument();
    }, 0);
  },
  props: {
    trainingDocumentSlug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    trainingDocument: {} as { title: string; content: string; },
  }),
  created() {
    this.loadTrainingDocument();
  },
  methods: {
    async loadTrainingDocument() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `business-portal/training_documents/?slug=${this.trainingDocumentSlug}`,
      });

      if (responseData.status === 200) {
        this.trainingDocument = responseData.body;
        this.status = 'loaded';
      } else if (responseData.status === 404) {
        this.status = 'not_found';
      } else {
        this.status = 'error';
      }
    },
  },
});
