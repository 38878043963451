<template>
  <div class="component horizontal-padding">
    <spinner
      v-if="status === 'loading'"
      preset="large"
    />

    <template v-else-if="status === 'error'">
      <alert variant="danger">
        An error occurred loading the training document. Please check your
        connection and try again.
      </alert>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="loadTrainingDocument"
      >
        Try Again
      </button>
    </template>

    <page-not-found-content v-else-if="status === 'not_found'" />

    <template v-else>
      <h1>{{ trainingDocument.title }}</h1>

      <div v-html="trainingDocument.content" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PageNotFoundContent from '@/components/PageNotFoundContent.vue';

export default defineComponent({
  components: {
    PageNotFoundContent,
  },
  async beforeRouteUpdate(to, from, next) {
    next();

    setTimeout(() => {
      this.loadTrainingDocument();
    }, 0);
  },
  props: {
    trainingDocumentSlug: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    trainingDocument: {} as { title: string; content: string; },
  }),
  created() {
    this.loadTrainingDocument();
  },
  methods: {
    async loadTrainingDocument() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `business-portal/training_documents/?slug=${this.trainingDocumentSlug}`,
      });

      if (responseData.status === 200) {
        this.trainingDocument = responseData.body;
        this.status = 'loaded';
      } else if (responseData.status === 404) {
        this.status = 'not_found';
      } else {
        this.status = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .component {
    word-break: break-word;
  }

  :deep(img),
  :deep(video) {
    max-width: 100%;
    height: auto;
  }
</style>
